// import logo from "./logo.svg";
import "./Footer.css";
import { BrowserRouter, Routes, Route, Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFilePlusMinus,
  faChartLine,
  faCalendarRange,
  faMegaphone,
} from "@fortawesome/pro-thin-svg-icons";

function Footer(props) {
  return (
    <div className="footer">
      <div className="container cols">
        <div>
          <img src={require("../../assets/tt_logo_black.png")} />
        </div>
        <div>
          <h4>CONTACT US</h4>
          <p>TuneTracer AB</p>
          <p>Skeppsbron 36, 111 30 Stockholm</p>
          <p>
            <a className="mail-link" href="mailto:eric@techtify.se">
              Reach out via email
            </a>
          </p>
        </div>
        {/* <div>
          <h4>JOIN US</h4>
          <p>
            <a href="/">Careers</a>
          </p>
          <p>
            <a href="/">Investors</a>
          </p>
          <p>
            <a href="/">Partners</a>
          </p>
          <p>
            <a href="/">Press</a>
          </p>
        </div> */}
        <div>
          <h4>NAVIGATE</h4>
          <p>
            <a href="/our-mission">Our mission</a>
          </p>
          <p>
            <a href="/pricing">Pricing</a>
          </p>
          <p>
            <a href="/terms-of-use">Terms of use</a>
          </p>
          {/* <p>
            <a href="/">Log in</a>
          </p> */}
        </div>
      </div>
    </div>
  );
}

export default Footer;
