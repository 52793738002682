// import logo from "./logo.svg";
import "./Pricing.css";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircle } from "@fortawesome/pro-thin-svg-icons";
import { faCircleDot } from "@fortawesome/pro-solid-svg-icons";
import { useEffect, useState } from "react";
function Pricing() {
  const [ww, setWw] = useState(window.innerWidth);
  const tableHeadData = {
    feature: "Feature",
    basic: "Basic",
    planner: "Planner (coming soon)",
    pro: "Pro (coming soon)",
  };
  const tableData = [
    {
      title: "Yearly royalty difference",
      basic: true,
      planner: true,
      pro: true,
    },
    {
      title: "Work consumption stats (updates daily)",
      basic: true,
      planner: true,
      pro: true,
    },
    {
      title: "Calendar function",
      basic: false,
      planner: true,
      pro: true,
    },
    {
      title: "Mentions module",
      basic: false,
      planner: false,
      pro: true,
    },
  ];
  const tableFooterData = {
    feature: "Monthly price",
    basic: "99 SEK",
    planner: "129 SEK",
    pro: "199 SEK",
  };
  useEffect(() => {
    const handleResize = (val) => {
      // Perform actions on window resize
      setWw(val.currentTarget.window.innerWidth);
      // console.log("VAL", val.currentTarget.window.innerWidth);
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  return (
    <div id="Pricing">
      <div className="container">
        <div className="hero">
          <h1>We'd love to do this for free</h1>
          <p>
            In a perfect world we would. In reality unfortunately, running a
            business isn't free. Second best we could do was to try to set the
            pricing so that it should be possible for each and every one of you
            to afford it. We think we made it.
          </p>
        </div>
      </div>
      <div className="squares">
        <div>
          <h2>Yearly royalty difference</h2>
          <img src={require("../../assets/diff.png")} />
        </div>
        <div>
          <h2>Work consumption stats</h2>
          <img src={require("../../assets/stats.png")} />
        </div>
        <div>
          <h2>
            Calendar function <span>(coming soon)</span>
          </h2>
          <img src={require("../../assets/calendar.png")} />
        </div>
        <div>
          <h2>
            Mentions module <span>(coming soon)</span>
          </h2>
          <img src={require("../../assets/mentions.png")} />
        </div>
      </div>
      <div className="inner">
        <div className="stripes"></div>
        <div className="content">
          {/* <h2>Our core purpose</h2> */}
          {ww > 1024 ? (
            <div className="pricing">
              <div className="head">
                <p>{tableHeadData.feature}</p>
                <p>{tableHeadData.basic}</p>
                <p>{tableHeadData.planner}</p>
                <p>{tableHeadData.pro}</p>
              </div>
              <div className="body">
                {tableData.map((item, i) => {
                  return (
                    <div key={i} className="row">
                      <p>{item.title}</p>
                      <p>
                        <FontAwesomeIcon
                          icon={item.basic ? faCircleDot : faCircle}
                          color="#bea45a"
                          size="lg"
                        />
                      </p>
                      <p>
                        <FontAwesomeIcon
                          icon={item.planner ? faCircleDot : faCircle}
                          color="#bea45a"
                          size="lg"
                        />
                      </p>
                      <p>
                        <FontAwesomeIcon
                          icon={item.pro ? faCircleDot : faCircle}
                          color="#bea45a"
                          size="lg"
                        />
                      </p>
                    </div>
                  );
                })}
              </div>
              <div className="tableFooter">
                <p>{tableFooterData.feature}</p>
                <p>{tableFooterData.basic}</p>
                <p>{tableFooterData.planner}</p>
                <p>{tableFooterData.pro}</p>
              </div>
            </div>
          ) : (
            <div className="mobile-pricing">
              <div className="item">
                <div className="title">
                  <h2>Basic</h2>
                  <p>99 SEK/mon</p>
                </div>
                <div className="list">
                  <p>
                    <FontAwesomeIcon
                      icon={faCircleDot}
                      color="#bea45a"
                      size="lg"
                    />
                    Yearly royalty difference
                  </p>
                  <p>
                    <FontAwesomeIcon
                      icon={faCircleDot}
                      color="#bea45a"
                      size="lg"
                    />
                    Work consumption stats (updates daily)
                  </p>
                  <p>
                    <FontAwesomeIcon
                      icon={faCircle}
                      color="#bea45a"
                      size="lg"
                    />
                    Calendar function
                  </p>
                  <p>
                    <FontAwesomeIcon
                      icon={faCircle}
                      color="#bea45a"
                      size="lg"
                    />
                    Mentions module
                  </p>
                </div>
              </div>
              <div className="item">
                <div className="title">
                  <h2>Planner</h2>
                  <p>129 SEK/mon</p>
                </div>

                <div className="list">
                  <p>
                    <FontAwesomeIcon
                      icon={faCircleDot}
                      color="#bea45a"
                      size="lg"
                    />
                    Yearly royalty difference
                  </p>
                  <p>
                    <FontAwesomeIcon
                      icon={faCircleDot}
                      color="#bea45a"
                      size="lg"
                    />
                    Work consumption stats (updates daily)
                  </p>
                  <p>
                    <FontAwesomeIcon
                      icon={faCircleDot}
                      color="#bea45a"
                      size="lg"
                    />
                    Calendar function
                  </p>
                  <p>
                    <FontAwesomeIcon
                      icon={faCircle}
                      color="#bea45a"
                      size="lg"
                    />
                    Mentions module
                  </p>
                </div>
              </div>
              <div className="item">
                <div className="title">
                  <h2>Pro</h2>
                  <p>199 SEK/mon</p>
                </div>
                <div className="list">
                  <p>
                    <FontAwesomeIcon
                      icon={faCircleDot}
                      color="#bea45a"
                      size="lg"
                    />
                    Yearly royalty difference
                  </p>
                  <p>
                    <FontAwesomeIcon
                      icon={faCircleDot}
                      color="#bea45a"
                      size="lg"
                    />
                    Work consumption stats (updates daily)
                  </p>
                  <p>
                    <FontAwesomeIcon
                      icon={faCircleDot}
                      color="#bea45a"
                      size="lg"
                    />
                    Calendar function
                  </p>
                  <p>
                    <FontAwesomeIcon
                      icon={faCircleDot}
                      color="#bea45a"
                      size="lg"
                    />
                    Mentions module
                  </p>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default Pricing;
