import hero_img from "../../assets/hero_img.png";
import "./LandingPage.css";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Hero from "../../Components/Hero/Hero";
import Mission from "../../Components/Mission/Mission";
import Features from "../../Components/Features/Features";

function LandingPage() {
  return (
    <div id="LandingPage">
      <Hero />
      <Mission />
      <Features />
    </div>
  );
}

export default LandingPage;
