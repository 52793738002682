// import logo from "./logo.svg";
import "./Mission.css";
import { BrowserRouter, Routes, Route, Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLongArrowRight } from "@fortawesome/pro-light-svg-icons";
function Mission(props) {
  return (
    <div className="mission">
      <div className="mission-img">
        {/* <div className="abstract-wrp">
          <div className="inner"> */}
        <img className="abstract" src={require("../../assets/abstract.png")} />
        {/* </div>
        </div> */}
        <div className="stripes"></div>
      </div>
      <div className="text-wrapper">
        <h2>Follow your tunes.</h2>
        <p>
          We believe that transparency and insight is crucial for all
          performers, creators and rights holders. Our mission is to provide you
          with insights on your work while consistently being the voice for the
          revolution of today's royalty reimbursement system.
        </p>
        <div className="btn-wrp">
          <Link to={"/our-mission"}>
            Read more about our mission{" "}
            {/* <FontAwesomeIcon
              icon={faLongArrowRight}
              color="#bea45a"
              size="lg"
              style={{ marginLeft: 10 }}
            /> */}
          </Link>
        </div>
      </div>
    </div>
  );
}

export default Mission;
