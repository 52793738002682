import logo from "./logo.svg";
import "./App.css";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import LandingPage from "./Views/LandingPage/LandingPage";
import UiLayout from "./Ui/UiLayout";
import OurMission from "./Views/OurMission/OurMission";
import Pricing from "./Views/Pricing/Pricing";
import TermsOfUse from "./Views/TermsOfUse/TermsOfUse";
function App() {
  // return (
  //   <div className="App">
  //     <header className="App-header">
  //       <img src={logo} className="App-logo" alt="logo" />
  //       <p>
  //         Edit <code>src/App.js</code> and save to reload.
  //       </p>
  //       <a
  //         className="App-link"
  //         href="https://reactjs.org"
  //         target="_blank"
  //         rel="noopener noreferrer"
  //       >
  //         Learn React
  //       </a>
  //     </header>
  //   </div>
  // );
  return (
    <BrowserRouter>
      <UiLayout>
        <Routes>
          <Route path="/" Component={LandingPage} />
          <Route path="/our-mission" Component={OurMission} />
          <Route path="/pricing" Component={Pricing} />
          <Route path="/terms-of-use" Component={TermsOfUse} />
        </Routes>
      </UiLayout>
    </BrowserRouter>
  );
}

export default App;
