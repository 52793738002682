// import logo from "./logo.svg";
import { useEffect } from "react";
import "./UiLayout.css";
import Footer from "../Components/Footer/Footer";
import { BrowserRouter, Routes, Route, NavLink } from "react-router-dom";

function UiLayout(props) {
  return (
    <div className="App">
      <header id="Header" className="header">
        <div className="container">
          <a href="/">
            <img
              src={require("../assets/tt_logo_white_no_slogan.png")}
              className="logo"
              alt="logo"
            />
          </a>
          <menu>
            <NavLink to="/our-mission">Our mission</NavLink>
            <NavLink to="/pricing">Pricing</NavLink>
            {/* <a className="login" href="/">
              Log in
            </a> */}
          </menu>
        </div>
      </header>
      {props.children}
      <Footer />
    </div>
  );
}

export default UiLayout;
