// import logo from "./logo.svg";
import "./Hero.css";
import { BrowserRouter, Routes, Route } from "react-router-dom";

function UiLayout(props) {
  return (
    <div className="hero">
      <div className="container">
        <div className="text-wrapper">
          <h1>It's time to set things right.</h1>
          <h3>
            The royalty payout system is flawed. We want to fix that by enabling
            you to get the reimbursement you deserve while keeping track of the
            global consumption of your life's work.
          </h3>
          {/* <h3>All in one platform.</h3> */}
          <div className="btn-wrp">
            <h3>Join the cause.</h3>
            {/* <a href="/">Create an account</a> */}
          </div>
        </div>
        <div className="hero-img-wrapper">
          <img
            src={require("../../assets/hero-img-fade.png")}
            className="hero-img"
            alt="hero-img"
            // style={{ width: 536 }}
          />
        </div>
      </div>
    </div>
  );
}

export default UiLayout;
