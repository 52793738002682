// import logo from "./logo.svg";
import "./OurMission.css";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPeopleGroup,
  faMagnifyingGlassChart,
  faLayerGroup,
} from "@fortawesome/pro-thin-svg-icons";

function OurMission() {
  return (
    <div id="OurMission">
      <div className="container">
        <div className="hero">
          <h1>We're on a mission</h1>
          <p>
            The royalty reimbursement process around the world is outdated,
            complex and ambiguous. This results in royalty not reaching the
            correct rights holder. Our aim is to stand up for performers,
            creators and rights holders by shedding a light on the faulty wiring
            and thus empowering them to take matters into their own hands. As a
            collective, we can make a change.
          </p>
          <p>Join us.</p>
        </div>
      </div>
      <div className="inner">
        <div className="stripes"></div>
        <div className="content">
          <h2>Our core purpose</h2>
          <div className="purposes">
            <div>
              <FontAwesomeIcon
                icon={faMagnifyingGlassChart}
                color="#bea45a"
                size="4x"
              />
              <h3>Insight</h3>
              <p>
                Every change starts with gaining insight and understanding of
                the problem. By utilizing our in-house competence together with
                advanced AI we can provide that insight through data.
              </p>
            </div>{" "}
            <div>
              <FontAwesomeIcon icon={faPeopleGroup} color="#bea45a" size="4x" />
              <h3>Collective</h3>
              <p>
                In order to invoke change in large, well-established
                corporations and organizations the voice of many are crucial.
                Lets speak up together and make this crucial change.
              </p>
            </div>{" "}
            <div>
              <FontAwesomeIcon icon={faLayerGroup} color="#bea45a" size="4x" />
              <h3>Platform</h3>
              <p>
                Speaking up is made possible with the right platform. We’ve
                created a transparent and intuitive platform for you to stand on
                while embarking on the pursuit of your fair reimbursement.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default OurMission;
