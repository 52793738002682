// import logo from "./logo.svg";
import "./Features.css";
import { BrowserRouter, Routes, Route, Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFilePlusMinus,
  faChartLine,
  faCalendarRange,
  faMegaphone,
} from "@fortawesome/pro-thin-svg-icons";

function Features(props) {
  return (
    <div className="features">
      <div className="container">
        <div>
          <FontAwesomeIcon icon={faFilePlusMinus} color="#bea45a" size="4x" />
          <h2>Yearly royalty difference</h2>
          <p>
            Once a year, we compare, summarize and visualize the difference
            between your money received and your actual money earned.
          </p>
        </div>
      </div>
      <div className="container cols">
        <div>
          <FontAwesomeIcon icon={faChartLine} color="#bea45a" size="4x" />
          <h2>Tracking and statistics</h2>
          <p>
            Follow the consumption of your work on the largest platforms like
            Spotify, Youtube and radio stations globally.
          </p>
        </div>
        <div>
          <FontAwesomeIcon icon={faCalendarRange} color="#bea45a" size="4x" />
          <h2>Plan your time</h2>
          <p>
            Use our custom built calendar function in order to plan an visualize
            your daily todos.
          </p>
          <span>(coming soon)</span>
        </div>
        <div>
          <FontAwesomeIcon icon={faMegaphone} color="#bea45a" size="4x" />
          <h2>See where the fuzz is</h2>
          <p>
            Keep track of what's being said about you in both printed and
            digital media with Mentions.
          </p>
          <span>(coming soon)</span>
        </div>
      </div>
    </div>
  );
}

export default Features;
