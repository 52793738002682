// import logo from "./logo.svg";
import "./TermsOfUse.css";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPeopleGroup,
  faMagnifyingGlassChart,
  faLayerGroup,
} from "@fortawesome/pro-thin-svg-icons";

function TermsOfUse() {
  return (
    <div id="TermsOfUse">
      <div className="container">
        <div>
          <h1>TUNE TRACER - USER AGREEMENT</h1>
          <h3>
            This User Agreement ("Agreement") is entered into by and between
            Techtify AB, a company registered under the laws of [Your
            Country/State], ("Company"), and you ("User"), collectively referred
            to as the "Parties," governing the use of the Company's Software as
            a Service platform ("Service").
          </h3>
          <p className="bold">1. Acceptance of Terms</p>
          <p>
            By accessing or using the Service, User agrees to be bound by the
            terms and conditions of this Agreement. If User does not agree with
            any part of this Agreement, User must not use the Service.
          </p>
          <p className="bold">2. Description of Service</p>
          <p>
            The Service provided by Company includes collection and comparisons
            of datasets regarding the users intellectual properties within the
            music industry. The Service may be accessed through a web browser.
          </p>
          <p className="bold">3. User Accounts</p>
          <p>
            User must create an account to access the features of the Service.
            User agrees to provide accurate, current, and complete information
            during the registration process and to update such information to
            keep it accurate, current, and complete. User is responsible for
            maintaining the confidentiality of User's account login credentials.
          </p>
          <p className="bold">4. License</p>
          <p>
            Subject to the terms and conditions of this Agreement, Company
            grants User a non-exclusive, non-transferable, revocable license to
            use the Service for User's internal business purposes.
          </p>
          <p className="bold">5. Payment</p>
          <p>
            User agrees to pay all fees, if any, associated with the use of the
            Service as set forth in Company's pricing schedule. Fees are
            non-refundable unless otherwise stated by Company.
          </p>
          <p className="bold">6. Intellectual Property</p>
          <p>
            User acknowledges that all intellectual property rights in the
            Service, including but not limited to copyrights, patents,
            trademarks, and trade secrets, are owned by Company or its
            licensors.
          </p>
          <p className="bold">7. Privacy</p>
          <p>
            Company's collection and use of personal information operates within
            the rules of GDPR (General Data Protection Regulation). By using the
            Service, User consents to such collection and use.
          </p>
          <p className="bold">8. Prohibited Conduct</p>
          <p>
            User agrees not to engage in any conduct that violates this
            Agreement or infringes on the rights of Company or any third party.
          </p>
          <p className="bold">9. Warranty Disclaimer</p>
          <p>
            The Service is provided "as is" and "as available" without
            warranties of any kind, either express or implied. Company disclaims
            all warranties, including but not limited to the implied warranties
            of merchantability, fitness for a particular purpose, and
            non-infringement.
          </p>
          <p className="bold">10. Limitation of Liability</p>
          <p>
            In no event shall Company be liable for any indirect, incidental,
            special, consequential, or punitive damages arising out of or
            related to this Agreement or the use of the Service.
          </p>
          <p className="bold">11. Termination</p>
          <p>
            Company may terminate this Agreement and User's access to the
            Service at any time for any reason without prior notice.
          </p>
          <p className="bold">12. Governing Law</p>
          <p>
            This Agreement shall be governed by and construed in accordance with
            the laws of Sweden.
          </p>
          <p className="bold">13. Changes to Agreement</p>
          <p>
            Company reserves the right to modify this Agreement at any time.
            User's continued use of the Service after such modifications shall
            constitute User's acceptance of the modified Agreement.
          </p>
          <p className="bold">14. Entire Agreement</p>
          <p>
            This Agreement constitutes the entire agreement between the Parties
            regarding the subject matter hereof and supersedes all prior or
            contemporaneous agreements, understandings, and representations,
            whether oral or written.
          </p>
          <p className="bold">15. Contact Information</p>
          <p>
            If you have any questions about this Agreement, please contact
            Techtify AB at eric@techtify.se.
          </p>
        </div>
      </div>
    </div>
  );
}

export default TermsOfUse;
